export enum RoleType {
  SERVICE = "SERVICE",
  SUPER_USER = "SUPER_USER",
  INTERNAL_EMPLOYEE = "INTERNAL_EMPLOYEE",
  LEARNING_ADMIN = "LEARNING_ADMIN",
  POST_AUTH_PRE_VIAS = "POST_AUTH_PRE_VIAS",
  LEARNING_ACCOUNT_IT_CONTACT = "LEARNING_ACCOUNT_IT_CONTACT",
  LEARNER = "LEARNER",
  ILT_INSTRUCTOR = "ILT_INSTRUCTOR",
  TRAINING_OPERATIONS = "TRAINING_OPERATIONS",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  DEACTIVATED = "DEACTIVATED",
}
