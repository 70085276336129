import { useAuth } from "./hooks/useAuth";
import { Renderer } from "./components/Renderer";
import { getSessionQueryParams, setSessionQueryParams } from "./common/utilityFunctions";
import { useEffect, useState } from "react";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import { Spinner } from "@amzn/awsui-components-react";
import "./App.scss";
import { PrimaryNavigation } from "./components/PrimaryNavigation";
import { useGetCatalogItemById } from "./queries/catalogItemById";
import { getNodeEnvironment, NodeEnvironment } from "./common/nodeEnvironment";
import { SB_LINK } from "./common/constants";

const App = (): JSX.Element => {
  // TODO: SETUP LOCALIZATION
  // here we need to set query params in session memory before redirect to auth
  // this is a temporary implementatin as authgateway and gandalf currently do not support custom query params
  setSessionQueryParams();
  const logoLink = SB_LINK[getNodeEnvironment().toUpperCase() as keyof typeof NodeEnvironment];
  const [error, setError] = useState<string | undefined>(undefined);
  const [buttonLink, setButtonLink] = useState<string>(logoLink);
  const [activityId, setActivityId] = useState<string | undefined>(undefined);
  const { user } = useAuth();

  const [getCatalogItemById, { data: catalogItemData, loading: catalogItemLoading, error: catalogItemError }] =
    useGetCatalogItemById();

  // get the query params from session memory
  const searchParams = new URLSearchParams(getSessionQueryParams());
  const catalogProductId = searchParams.get("product_id");
  const catalogModuleId = searchParams.get("module_id");
  const navigation = searchParams.get("navigation");
  const registrationId = searchParams.get("registration_id");

  useEffect(() => {
    if (!catalogModuleId) {
      // TODO: SETUP MORE ROBUST ERROR HANDLING AND PAGES IN LATER TASK
      // https://tasks.awstcprojects.com/browse/CATALOG-3965
      setError("Cannot find required query parameters.");
    }
  }, []);

  useEffect(() => {
    if (catalogProductId) {
      getCatalogItemById({ variables: { id: catalogProductId } });
    }
  }, [catalogProductId]);

  useEffect(() => {
    if (catalogItemData && catalogItemData.catalogItemByVersionedId) {
      setButtonLink(catalogItemData.catalogItemByVersionedId.trainingObjectUrl ?? logoLink);
      const module = catalogItemData.catalogItemByVersionedId.children.nodes.find((child) =>
        catalogModuleId?.includes(child.id)
      );
      let outline;
      if (module && module.outline) {
        outline = JSON.parse(module.outline);
      }
      if (outline && outline.length === 1) {
        setActivityId(outline[0].id);
      }
    }
  }, [catalogItemData]);

  return (
    <>
      {navigation && catalogProductId && (
        <PrimaryNavigation
          productId={catalogProductId}
          navigationContext={navigation}
          buttonLink={buttonLink}
          logoLink={logoLink}
        />
      )}
      {error && <TextContent>Error - {error}</TextContent>}
      {!error && !user.isLoggedIn && (
        <div className="spinner">
          <Spinner size="large" />
        </div>
      )}
      {!error && user?.isLoggedIn && (
        <Renderer
          userId={user.id}
          vibeId={user.vibeId}
          activityId={activityId}
          catalogModuleId={searchParams.get("module_id")!}
          catalogProductId={catalogProductId}
          registrationId={registrationId}
          context={navigation}
        />
      )}
    </>
  );
};
export default App;
