import { AwsRumConfig } from "aws-rum-web";
import { getNodeEnvironment, NodeEnvironment } from "../common/nodeEnvironment";

// Add the values from your personal Isengard account after deploying AwsTcCheckoutFrontendCDK
export const DEV_ALIAS = "";
export const DEV_RUM_GUEST_ROLE_ARN = "";
export const DEV_RUM_IDENTITY_POOL_ID = "";
export const DEV_RUM_APP_ID = "";

// Constant RUM values for all stages
export const RUM_APP_VERSION = "1.0.0";
export const RUM_APP_REGION = "us-east-1";
export const RUM_APP_ENDPOINT = "https://dataplane.rum.us-east-1.amazonaws.com";
export const RUM_APP_TELEMETRIES = ["errors", "http", "performance"];

export interface RumStageProps {
  appId: string;
  rumStageConfig: AwsRumConfig;
}

export const DEV_RUM: RumStageProps = {
  appId: DEV_RUM_APP_ID,
  rumStageConfig: {
    guestRoleArn: DEV_RUM_GUEST_ROLE_ARN,
    identityPoolId: DEV_RUM_IDENTITY_POOL_ID,
    sessionSampleRate: 0.5,
  },
};

export const BETA_RUM: RumStageProps = {
  appId: "5a424b5b-7f89-4c3a-89f9-f094342d04a2",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::211125495812:role/RendererRumGuestRole",
    identityPoolId: "us-east-1:4482b6e6-a2d5-4e79-ab8b-a8087a878e51",
    sessionSampleRate: 0.5,
  },
};

export const GAMMA_RUM: RumStageProps = {
  appId: "e6ddc1ae-152e-4015-8776-8634da521058",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::851725607835:role/RendererRumGuestRole",
    identityPoolId: "us-east-1:9adce558-142c-4ddd-97ce-3869d112e835",
    sessionSampleRate: 0.5,
  },
};

// TODO: Add Configs when PROD is deployed
export const PROD_RUM: RumStageProps = {
  appId: "b53083ae-d65e-450e-9c13-8739c50eb545",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::730335621640:role/RendererRumGuestRole",
    identityPoolId: "us-east-1:34f07c99-f4b1-403c-affe-9179e75c91a8",
    sessionSampleRate: 1,
  },
};

export function getCurrentRumStage(): RumStageProps {
  const env = getNodeEnvironment();
  switch (env) {
    case NodeEnvironment.BETA:
      return BETA_RUM;
    case NodeEnvironment.GAMMA:
      return GAMMA_RUM;
    case NodeEnvironment.PROD:
      return PROD_RUM;
    default:
      return DEV_RUM;
  }
}
