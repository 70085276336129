/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { ApplicationUser } from "../auth/ApplicationUser";
import { getSignInURL, refreshToken, signOut } from "../auth/authHelpers";
import { useGetCurrentUser } from "../user/api/getCurrentUser";
import { UserStatus } from "../user/types";
import { GandalfProps } from "../auth/authHelpers";

export type AuthResponse = {
  readonly user: ApplicationUser;
};

export const useAuth = (): AuthResponse => {
  const [signedInUserEmail, setSignedInUserEmail] = useState<string | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [id, setId] = useState<string | undefined>(undefined);
  const [vibeId, setVibeId] = useState<string | undefined>(undefined);
  const params = new URLSearchParams(window.location.search);
  const identity_provider = params.get("identity_provider");
  let gandalfProps: GandalfProps;

  if (sessionStorage.getItem("thirdPartyAuthSignedIn")) {
    refreshToken()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }

  if (identity_provider && !sessionStorage.getItem("thirdPartyAuthSignedIn")) {
    gandalfProps = {
      identity_provider: identity_provider,
      require_email_verification: "false",
    };
    sessionStorage.setItem("thirdPartyAuthSignedIn", "true");
    signOut().then(() => (window.location.href = getSignInURL(gandalfProps)));
  }

  if (!identity_provider && !sessionStorage.getItem("thirdPartyAuthSignedIn")) {
    const { data, error } = useGetCurrentUser();
    useEffect(() => {
      if (error) {
        // TODO - not priority - setup an error screen here instead?
        setIsLoggedIn(false);
        window.location.href = getSignInURL();
      }

      if (data) {
        const currentUser = data.currentUser;
        if (currentUser.status !== UserStatus.ACTIVE) {
          setIsLoggedIn(false);
          // SignOut is called to revoke tokens/clear HttpOnly cookies - as we want the user to be able to try again w/ different credentials
          signOut().then(() => (window.location.href = getSignInURL()));
        } else {
          refreshToken()
            .then(() => {
              setSignedInUserEmail(currentUser.emailAddress);
              setIsLoggedIn(true);
              setId(currentUser.id);
              setVibeId(currentUser.gandalfDetails.vibeId);
            })
            .catch(() => {
              setIsLoggedIn(false);
              signOut().then(() => (window.location.href = getSignInURL()));
            });
        }
      }
    }, [data, error]);
  }

  const potentialUser = new ApplicationUser({
    email: signedInUserEmail,
    isLoggedIn: isLoggedIn,
    id: id,
    vibeId: vibeId,
  });

  return {
    user: potentialUser,
  };
};
