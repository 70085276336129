import { NodeEnvironment, getNodeEnvironment } from "../nodeEnvironment";
import { APPLICATION_URL } from "../constants";

const endpoints = {
  // Point dev to local fastify server and others to beta supergraph
  [NodeEnvironment.TEST]: `https://beta.api.onboard.skillbuilder.training.aws.dev/graphql`,
  [NodeEnvironment.DEVELOPMENT]: `http://localhost:3000/graphql`,
  [NodeEnvironment.BETA]: `${APPLICATION_URL.BETA}/graphql`,
  [NodeEnvironment.GAMMA]: `${APPLICATION_URL.GAMMA}/graphql`,
  [NodeEnvironment.PROD]: `${APPLICATION_URL.PROD}/graphql`,
};

export const getClientEndpoint = () => {
  return endpoints[getNodeEnvironment()] || endpoints[NodeEnvironment.TEST];
};
