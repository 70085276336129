// Promise polyfill
import "core-js/features/promise";
import "@amzn/awsui-global-styles/polaris.css";
import { createRoot } from "react-dom/client";

import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { client } from "./common/api/client";
import { rumInit } from "./clientMonitoring/rumClient";

rumInit();
document.addEventListener("DOMContentLoaded", () => {
  createRoot(document.querySelector("#app")!).render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
});
