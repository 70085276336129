import { useEffect, useState } from "react";
import {
  CONTENT_DELIVERY_SERVICE_URL_AUTH,
  CONTENT_DELIVERY_SERVICE_URL_PUBLIC,
  LRS_ENDPOINT,
} from "../common/constants";
import { getNodeEnvironment, NodeEnvironment } from "../common/nodeEnvironment";
import { formatActor } from "../common/utilityFunctions";
import { LoadingSkeleton } from "./LoadingSkeleton";
import "./Renderer.scss";
import { useGetCatalogItemById } from "../queries/catalogItemById";

export interface RendererProps {
  userId?: string;
  vibeId?: string;
  activityId?: string;
  catalogModuleId: string;
  catalogProductId: string | null;
  registrationId: string | null;
  context?: string | null;
}

export const Renderer = (props: RendererProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // if we are serving free content, then we need to go ahead and render the full /index.html path here
  // this is because we do not have CDS to redirect for us, which gives the cousre player the proper context
  // otherwise, the course player thinks the assets are stored in Renderer
  // this was found through testing
  const cdsDomain =
    CONTENT_DELIVERY_SERVICE_URL_AUTH[getNodeEnvironment().toUpperCase() as keyof typeof NodeEnvironment];
  const lrsEndpoint = LRS_ENDPOINT[getNodeEnvironment().toUpperCase() as keyof typeof NodeEnvironment];

  // the URL we are rendering here is the CDS url from above
  // we are also appending some query params mainly for LRS and course player
  // these include the LRS endpoint, the actor information (user information including id and vibe id), catalog module id
  const renderingUrl = new URL(`${cdsDomain}`);
  renderingUrl.searchParams.append("endpoint", lrsEndpoint);
  renderingUrl.searchParams.append("actor", formatActor(props.userId, props.vibeId));
  renderingUrl.searchParams.append("module_id", props.catalogModuleId);

  // registration id is only applicable in the 1P use case, so it is optional
  if (props.registrationId) {
    // Note: We are passing both these values because registration_id is passed in query param to LRS
    // while registration is passed in body according to the xapi course player spec
    renderingUrl.searchParams.append("registration_id", props.registrationId);
    renderingUrl.searchParams.append("registration", props.registrationId);
  }

  if (props.catalogProductId) {
    renderingUrl.searchParams.append("product_id", props.catalogProductId);
  }

  if (props.activityId) {
    renderingUrl.searchParams.append("activity_id", props.activityId);
  }

  if (props.context && props.context === "aci") {
    renderingUrl.searchParams.append("context", "aci");
  }

  return (
    <div className="rendererIframeDiv">
      {isLoading && <LoadingSkeleton />}
      <iframe
        title="Training Content"
        src={renderingUrl.toString()}
        width="100%"
        height="100%"
        aria-label="Training Content"
        data-testid="renderer_iframe"
        id="renderer_iframe"
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </div>
  );
};
