import { getNodeEnvironment, NodeEnvironment } from "./nodeEnvironment";
import { APPLICATION_URL, GANDALF_TOKEN_ISS } from "./constants";

export const getApplicationURL = (): APPLICATION_URL => {
  const nodeEnvironment: NodeEnvironment = getNodeEnvironment();
  switch (nodeEnvironment) {
    case NodeEnvironment.BETA: {
      return APPLICATION_URL.BETA;
    }
    case NodeEnvironment.GAMMA: {
      return APPLICATION_URL.GAMMA;
    }
    case NodeEnvironment.PROD: {
      return APPLICATION_URL.PROD;
    }
    default: {
      return APPLICATION_URL.DEVELOPMENT;
    }
  }
};

export const formatActor = (userId?: string, vibeId?: string): string => {
  return JSON.stringify({
    name: userId ?? "none",
    openid: `${GANDALF_TOKEN_ISS}|${vibeId ?? "none"}`,
    objectType: "Agent",
  });
};

export const setSessionQueryParams = (): void => {
  const queryParams = window.location.search;
  if (queryParams.length > 0) {
    sessionStorage.setItem("queryParams", queryParams);
  }
};

export const getSessionQueryParams = (): string => {
  return sessionStorage.getItem("queryParams") ?? "";
};
