import { gql, useQuery } from "@apollo/client";
import { RoleType, UserStatus } from "../types";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      emailAddress
      status
      gandalfDetails {
        vibeId
      }
    }
  }
`;

export const useGetCurrentUser = () => {
  return useQuery(GET_CURRENT_USER, {});
};

export interface CurrentUserQueryResponseData {
  id: string;
  emailAddress: string;
  status: UserStatus;
  gandalfDetails: {
    vibeId: string;
  };
}
