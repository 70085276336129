export enum APPLICATION_URL {
  TEST = "http://localhost:3000",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DEVELOPMENT = "http://localhost:3000",
  BETA = "https://beta.render.skillbuilder.training.aws.dev",
  GAMMA = "https://render.skillbuilder.training.aws.dev",
  PROD = "https://render.skillbuilder.aws",
}

export const CONTENT_DELIVERY_SERVICE_URL_AUTH = {
  TEST: `${APPLICATION_URL.DEVELOPMENT}/cds/auth`,
  DEVELOPMENT: `${APPLICATION_URL.DEVELOPMENT}/cds/auth`,
  BETA: `${APPLICATION_URL.BETA}/cds/auth`,
  GAMMA: `${APPLICATION_URL.GAMMA}/cds/auth`,
  PROD: `${APPLICATION_URL.PROD}/cds/auth`,
};

export const CONTENT_DELIVERY_SERVICE_URL_PUBLIC = {
  TEST: `${APPLICATION_URL.DEVELOPMENT}/public`,
  DEVELOPMENT: `${APPLICATION_URL.DEVELOPMENT}/public`,
  BETA: `${APPLICATION_URL.BETA}/public`,
  GAMMA: `${APPLICATION_URL.GAMMA}/public`,
  PROD: `${APPLICATION_URL.PROD}/public`,
};

export const LRS_ENDPOINT = {
  TEST: `${APPLICATION_URL.DEVELOPMENT}/lrs`,
  DEVELOPMENT: `${APPLICATION_URL.DEVELOPMENT}/lrs`,
  BETA: `${APPLICATION_URL.BETA}/lrs`,
  GAMMA: `${APPLICATION_URL.GAMMA}/lrs`,
  PROD: `${APPLICATION_URL.PROD}/lrs`,
};

export const SB_LINK = {
  TEST: "https://beta.skillbuilder.training.aws.dev",
  DEVELOPMENT: "https://beta.skillbuilder.training.aws.dev",
  BETA: "https://beta.skillbuilder.training.aws.dev",
  GAMMA: "https://skillbuilder.training.aws.dev",
  PROD: "https:/skillbuilder.aws",
};

export const PDP_PAGE = {
  TEST: `${SB_LINK.TEST}/learn`,
  DEVELOPMENT: `${SB_LINK.DEVELOPMENT}/learn`,
  BETA: `${SB_LINK.BETA}/learn`,
  GAMMA: `${SB_LINK.GAMMA}/learn`,
  PROD: `${SB_LINK.PROD}/learn`,
};

export enum RegistrationStatus {
  REGISTERED = "REGISTERED",
  WITHDRAWN = "WITHDRAWN",
  CANCELED = "CANCELED",
}

export const GANDALF_TOKEN_ISS = "https://gandalf-prod.auth.us-east-1.amazoncognito.com/us-east-1_KcXGNtlRY";
