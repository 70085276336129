import { Button } from "@amzn/awsui-components-react";
import "./PrimaryNavigation.scss";
import { SB_LINK } from "../common/constants";
import { getNodeEnvironment, NodeEnvironment } from "../common/nodeEnvironment";
import i18n from "../../i18n/config";
import { useGetCatalogItemById } from "../queries/catalogItemById";
import { useEffect, useState } from "react";

export interface PrimaryNavigationProps {
  productId: string;
  navigationContext: string;
  buttonLink: string;
  logoLink: string;
}

export const PrimaryNavigation = (props: PrimaryNavigationProps): JSX.Element => {
  return (
    <div className="navigation" data-testid="nav-logo">
      <div className="label">
        <a href={props.logoLink}>
          <img src="/publicAssets/aws-logo.svg" alt="AWS" />
          <h3>{i18n.t(props.navigationContext === "aci" ? "header_aci" : "header_skillbuilder") as React.ReactNode}</h3>
        </a>
      </div>
      <div className="button">
        <Button href={props.buttonLink} variant="inline-link" data-testid="nav-button">
          {i18n.t("header_return_to_course_details_button") as React.ReactNode}
        </Button>
      </div>
    </div>
  );
};
