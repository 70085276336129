export interface ApplicationUserInitProps {
  email?: string;
  isLoggedIn?: boolean;
  id?: string;
  vibeId?: string;
}
export class ApplicationUser {
  readonly email?: string;
  readonly isLoggedIn?: boolean;
  readonly id?: string;
  readonly vibeId?: string;

  constructor(initProps: ApplicationUserInitProps) {
    this.email = initProps.email;
    this.isLoggedIn = initProps.isLoggedIn;
    this.id = initProps.id;
    this.vibeId = initProps.vibeId;
  }
}
