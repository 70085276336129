import { gql, useLazyQuery, useQuery } from "@apollo/client";

export const catalogItemByIdQuery = gql`
  query CatalogItemById($id: ID!, $isPreview: Boolean!) {
    catalogItemByVersionedId(versionedId: $id) {
      id
      trainingObjectUrl
      children {
        nodes {
          id
          outline
          contentFiles @include(if: $isPreview) {
            id
            version
          }
        }
      }
    }
  }
`;

export const useGetCatalogItemById = () => {
  return useLazyQuery(catalogItemByIdQuery);
};
